<template>
  <div>
    <Container v-if="isshow" :mainpagedata="mainpagedata" :mydefault="mydefault"
               v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"
               v-on:getpageinfo="getPageinfoEvent"></Container>
  </div>
</template>

<script>
import Container from '@/components/Container.vue'
import axios from "axios";
import {ElLoading} from 'element-plus'


export default {
  name: "dvcredpacketcode",
  data() {
    return {
      mainpagedata: {
        mainonename: '设备红包码', // 当前页名称
        mainoneiconshow1: false, // 当前页-【刷新按钮】
        mainoneiconshow2: true, // 当前页-【返回按钮】
        maintwoiconshow: false, // 筛选-【高级筛选按钮】
        maintwoforminfo: {
          myinputoneisshow: true, //筛选查询-左侧字+input
          myinputone:{
            divinterval: '0', //框右侧距离
            titlewidth: '95px',  //标题宽度
            inputwidth: '220px',  //input宽度
            inputinterval: '40px', //input右侧距离
            input: [
              {
                ismust: true, //是否必填，true表示必填
                title: '输入搜索：',
                placeholder: '设备编号',
                name: 'dvcnum',
                content: '',
              }
            ],
          },
        }, // 筛选-【筛选查询】-控件信息
        mainthreeisshowbut: false, // 数据列表中-【添加按钮】
        mainthreeisshowset: false, // 数据列表中-复选框
        mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
        mainthreetableope: 44, // 数据列表中-操作-根据页面显示控件（整页）
        mainthreehandle: 150, // 数据列表中-操作-宽度
        mainthreetabletitle: [
          {
            fixedstatu: 'left',
            prop: 'dvcnum',
            label: '设备编号',
            width: '155'
          },
          {
            fixedstatu: false,
            prop: 'redpacketcode',
            label: '红包码',
            width: '150'
          },
          {
            fixedstatu: false,
            prop: 'redpacketstatus',
            label: '状态',
            width: ''
          },
          {
            fixedstatu: false,
            prop: 'money',
            label: '单个红包金额',
            width: ''
          },
          {
            fixedstatu: false,
            prop: 'redpacketnum',
            label: '单个设备红包数量',
            width: ''
          },
          {
            fixedstatu: false,
            prop: 'effectivetime',
            label: '有效时间',
            width: '250'
          },
          {
            fixedstatu: false,
            prop: 'received',
            label: '已领取',
            width: ''
          },
        ], // 数据列表中-数据表头
        mainthreestatus: true, // 数据列表中-属于search按钮表示状态
        mainthreetableData: [],// 数据列表中-具体数据
        totalCount: 1, //数据列表中-数据总条数
        lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

        //高级筛选
        myinputishow: true, //高级筛选-input单框
        myinput: {
          inputwidth: '100%',
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: '输入搜索：',
              placeholder: '用户ID/用户昵称/手机号',
              name: 'keywords',
              content: ''
            }
          ]
        },
        myselectisshow: true, //高级筛选-select单框
        myselect: {
          selectwidth: '100%',
          select: [
            {
              selectisshow: true,
              ismust: true,
              title: 'PLUS会员：',
              placeholder: '全部',
              name: 'plusmember',
              value: '',
              options: [
                {
                  value: '-1',
                  label: '全部',
                },
                {
                  value: '0',
                  label: '是',
                },
                {
                  value: '1',
                  label: '否',
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '用户类型:',
              placeholder: '全部',
              name: 'usertype',
              value: '',
              options: [
                {
                  value: '-1',
                  label: '全部',
                },
                {
                  value: '0',
                  label: '个人',
                },
                {
                  value: '1',
                  label: '商户',
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '来源:',
              placeholder: '全部',
              name: 'source',
              value: '',
              options: [
                {
                  value: '-1',
                  label: '全部',
                },
                {
                  value: '0',
                  label: '聚邻社自助版',
                },
                {
                  value: '1',
                  label: '聚邻社家庭版',
                },
                {
                  value: '2',
                  label: '聚邻社企业版',
                },
                {
                  value: '3',
                  label: '公众号',
                },
                {
                  value: '4',
                  label: '印工厂',
                },
                {
                  value: '5',
                  label: '省省团',
                },
                {
                  value: '6',
                  label: 'SaaS待定？',
                },
                {
                  value: '7',
                  label: '手机号注册',
                },
              ],
            },
            {
              selectisshow: true,
              ismust: false,
              title: '实名认证:',
              placeholder: '全部',
              name: 'realname',
              value: '',
              options: [
                {
                  value: '-1',
                  label: '全部',
                },
                {
                  value: '0',
                  label: '是',
                },
                {
                  value: '1',
                  label: '否',
                },
              ],
            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: '创建时间区间：',
              placeholder: {
                placeholderstart: '请选择开始时间',
                placeholderend: '请选择结束时间',
              },
              name: 'createtime',
              value: {
                valuestart: '',
                valueend: '',
                endstatus: true
              },
              rangeseparator: '至' //分隔符

            },
            {
              selecttimeduanisshow: true, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: true,
              title: '最后登录时间区间：',
              placeholder: {
                placeholderstart: '请选择开始时间',
                placeholderend: '请选择结束时间',
              },
              name: 'lastlogintime',
              value: {
                valuestart: '',
                valueend: '',
                endstatus: true
              },
              rangeseparator: '至' //分隔符

            }
          ]
        },
      }, // 当前Pagedata所需的data数据与控制
      mydefault: {
        myheadactive: "a6",    //该字段为string类型，是头部导航key值
        myasideactive: "83",  //该字段为string类型，是子页面的id
      }, //本页面的导航默认值-逆向绑定（可优化-通过当前页面的path值，对数据进行筛选对比，将值自动赋值，避免修改路由之后出现页面&导航不对应状况）
      mytoken: localStorage.getItem('token'),  //获取存储的token
      isshow: true  //用于判断页面模块是否存在

    }
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false
      this.$router.push('/').catch(error => error)
    } else {
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            addtime: '2020-10-01~2021-10-31' //该字段为必传项-当空值，表示查询当月数据
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
          })
          .catch(function (error) {
            console.log(error)
          })
    }
  },
  mounted() {

  },
  methods: {
    searchFormEvent(data) { //获取Container-Pagedata-Maintwo-高级筛选-开始检索返回的数据
      console.log('高级筛选-开始检索返回数据(页面):' + JSON.stringify(data))
      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: '正在飞速加载中...',
      })
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            keyword: data.keywords,
            status: data.status,
            level: data.agentlevel,
            area_code: data.citycode,
            addtime: '2020-10-01~2021-10-31'
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
            loading.close()
          })
          .catch(function (error) { // 请求失败处理
            console.log(error)
          })

    },
    getSeachinfoEvent(data) { //获取Container-Pagedata-Maintwo-搜索-返回的数据
      console.log('点击查询获取的data数组：' + JSON.stringify(data))
    },
    getPageinfoEvent(data) { //获取Container-Pagedata-Mainthree-数据列表-分页选择的数据

      //加载loading
      var loading = ElLoading.service({
        lock: true,
        text: '正在飞速加载中...',
      })
      //通过Ajax向后台获取数据
      axios.post('/admin/shopUser/index',
          {
            page: data,
            addtime: '2020-10-01~2021-10-31'
          },
          {
            headers: {
              'Authentication': this.mytoken
            }
          })
          .then(response => {
            this.myaxiosfunc(response.data.result)
            loading.close()
          })
          .catch(function (error) { // 请求失败处理
            console.log(error)
          })
    },
    myaxiosfunc(successdata) {
      var newdata = successdata.data
      for (var i = 0; i < newdata.length; i++) {
        //该字段表示search按钮，且status为1表示启用，2表示禁用
        newdata[i].switchstatus = 4
        //将省市区分割
        var arrcity = newdata[i].area_code.split(',');
        newdata[i].province = arrcity[0];
        newdata[i].city = arrcity[1];
        newdata[i].county = arrcity[2]
      }
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      this.mainpagedata.mainthreetableData = newdata;
      this.mainpagedata.totalCount = successdata.total
      this.mainpagedata.lastpage = successdata.last_page
    }
  },
  components: {
    Container,
  }
}
</script>

<style scoped>

</style>